import React from "react";
// import Posts from "../post/Posts";
import Footer from '../core/Footer';

const PublicAbout = () => (
    <>
        <div className="container-fluid">
            <br />
            <br />
            <br />
            <div className="row">
                <div className="col-md-12">
                    <h3>
                        Some details on the Substation Design Solution (SDS) Industry Consortium
                    </h3>
                    <p>
                        Substation Design Solution (SDS) Industry Consortium is made up of member utilities
                        and contract/design firms which have adopted or are investigating for adoption the
                        new technologies  or workflows to improve design, engineering, field construction,
                        operation and maintenance of utility projects.
                    </p>
                    <p>
                        The Consortium will meet, as needed, to establish common workflows, common toolkit
                        functionality, shared libraries, common standards, technology needs and any other
                        topics that help drive our vendor’s software development efforts according to an industry standard.
                    </p>
                    <p>
                        The SDS Industry Consortium will drive standards to promote common library development from
                        internal resources, external partners, and/or equipment manufacturers.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                </div>
                <div className="col-md-8">
                    <h3>Current Leadership 2025</h3>
                    <br />
                    <p>
                        <li>Consortium Chair – <b>Bill Gordon – Southern Company</b></li>
                        <li>Consortium Vice Chair – <b>Scott Dimpfl – American Electric Power</b></li>
                    </p>
                    <p>
                        <li>Electrical Committee Chair – <b>John Zwerlein - Pacific Gas & Electric Company</b></li>
                        <li>Electrical Committee Co-Chair – <b>Rick Leonhardt – US Power Partners</b></li>
                    </p>
                    <p>
                        <li>Physical Committee Chair – <b>Curtis Bussey – Southern Company</b></li>
                        <li>Physical Committee Co-Chair –<b>Nate Dwelley – GAI Consultants</b></li>
                    </p>
                    <p>
                        <li>Civil/Structural Committee Chair – <b>Randall Eckrote – Baltimore Gas & Electric</b></li>
                        <li>Civil/Structural Committee Co-Chair – <b>Michael Bussinger – Burns & McDonnell</b></li>
                    </p>
                    <p>
                        <li>Technology Committee Chair – <b>Jason Phillpott – Ampirical</b></li>
                        <li>Technology Committee Co-Chair – <b>Vacant</b></li>
                    </p>
                    <p>
                        <li>Business Strategy Committee Chair – <b>Crystal Myers – Duke Energy</b></li>
                        <li>Business Strategy Committee Co-Chair – <b>Jared Driggers – POWER Engineers</b></li>
                    </p>
                    <p>
                        <li>Innovative Solutions Committee Chair – <b>C.J. Keeton – Southern Company</b></li>
                        <li>Innovative Solutions Committee Co-Chair – <b>Andrew Atiyeh – PPL</b></li>
                    </p>
                    <p>
                        <li>Autodesk Representatives (non-voting) – <b>Jack Hopson</b></li>
                        <li>Bentley Representative (non-voting) – <b>Todd Lenhardt</b></li>
                        <li>Spatial Business Systems Representative (non-voting) – <b>Kevin Whyte</b></li>
                    </p>
                    <p>
                        <li>Contractor Advisor (non-voting) –<b>Jermey Morton</b></li>
                        <li>Contractor Advisor (non-voting) – <b>Jeff Spence</b></li>
                        <li>Manufacturing Advisor (non-voting) – <b>Erica Huggins Vogel</b></li>
                        <li>SDSIC Advisor (non-voting) – <b>Trevor Scullion</b></li>
                        <li>SDSIC Advisor (non-voting) – <b>Mark Nelson</b></li>
                    </p>
                    <p>
                        <li>Documentation Specialist (non-voting) –  <b>Haley Collins – Burns & McDonnell</b></li>
                        <li>Newsletter Writer (non-voting) – <b>Morgan James, POWER Engineers</b></li>
                    </p>
                    <p>
                        <li>Technical Advisor Autodesk Point of Contact (non-voting) –<b>Jack Hopson</b></li>
                        <li>Technical Advisor Bentley Point of Contact (non-voting) – <b>Todd Lenhardt</b></li>
                        <li>Technical Advisor SBS Point of Contact (non-voting) – <b>Kevin Whyte</b></li>
                    </p>
                    <br />
                    <br />
                    <br />
                </div>
                <div className="col-md-2">
                </div>
            </div>
        </div>

        <Footer />

    </>
);

export default PublicAbout;