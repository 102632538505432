import React, { useState, useEffect } from 'react';
import {
  useStripe, useElements,
  CardNumberElement, CardExpiryElement, CardCvcElement
} from '@stripe/react-stripe-js';
import { isAuthenticated } from "../auth";
import { stripePaymentMethodHandler } from './stripeScript';
import { sendMessage } from "../user/apiUser";
import { registerUser } from '../event/apiEvents'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function CheckoutForm(props) {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();



  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    setErrorMsg('');

    const paymentMethodObj = {
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name,
        email
      }
    };
    const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodObj);

    var description = "Payment for the SDSIC 2025 Event Conference. Receipt emailed to: " + email + '. Confirmation emails sent to:  '

    props.userList.forEach(user => {
      description = description + `${user.email}, `
    })
    
    stripePaymentMethodHandler({
      result: paymentMethodResult,
      amount: props.amount,
      description: description,
      email: email
    }, handleResponse);

  };

  // callback method to handle the response
  const handleResponse = async response => {
    setLoading(false);
    if (response.error) {
      setErrorMsg(typeof response.error === 'string' ? response.error : response.error.message);
      return;
    }
    props.setPaymentCompleted(response.success ? true : false);
    props.setReceiptUrl(response.receipt_url)
    props.setReceiptEmail(email)
    props.setReceiptName(name)

    await sendEmailReceipt(response.receipt_url, email, name);
    
    await registerUserAfterPay()
  };

  const registerUserAfterPay = async () => {

    const userList = props.userList

    userList.forEach(user => {
      const body = {
        eventId: props.eventId,
        firstName: user.firstName,
        lastName: user.lastName,
        companyName: user.companyName,
        country: user.country,
        stateProvince: user.stateProvince,
        workphone: user.workphone,
        email: user.email,
        meetings: [user.day_1, user.day_2, user.day_3, user.day_4],
        industry: user.industry,
        jobLevel: user.jobLevel,
        role: user.role,
        sponsorInterest: user.sponsorInterest,
        accessibility: user.accessibility,
        terms: true
      }
      
      registerUser(body, props.eventId).then(data => {
        if (data.error) {
          setError(data.error)
          setSuccess(false)
          
        } else {
          
          const attendeeMessage = {
            "subject": "Your registered for the SDSIC 2025 Event",
            "sender": { "email": "events@sdsconsortium.com", "name": "events@sdsconsortium.com" },
            "to": [{ "name": `${user.email}`, "email": `${user.email}` }],            
            "htmlContent": `
              <p>Hi ${user.firstName},</p>
              <p>
                Thank you for registering to attend the SDSIC 2025 Event in Chattanooga, TN.<br/>
                Watch for more emails and updates as we finalize the event schedule!<br/>
                All meetings and consortium activities will take place at the:<br/>
                <b>Westin Chattanooga</b><br/>
                <b>801 Pine Street, Chattanooga, TN 37402</b><br/>
                Phone: <b>423.531.4653</b><br/>
              </p>             
              <p>
                You will find the information for your online reservation below. If you have questions or need help with the link, please do not hesitate to ask. We appreciate your business and look forward to a successful event.<br/>
              </p>
              <p>
                <b>Event Summary:</b><br/>
                Start Date: <b>Sunday, April 27, 2025</b><br/>
                End Date: <b>Thursday, May 01, 2025</b><br/>
                Hotel(s) offering your special group rate:<br/>
                  The Read House Hotel<br/>
                  107 W. MLK Blvd<br/>
                  Chattanooga, TN 37402<br/> <br/>
                Here's your reservation link to make reservations:<br/>
                <a href="http://bookings.ihotelier.com/bookings.jsp?groupID=4663620&hotelID=97530">
                  Book your group rate for MESA Associates Substation Design Solution (SDS)
                </a>
              </p>
              <p>
                Thank you!<br/>
                <b>The SDSIC Leadership Team</b>
              </p>
            `
          }

          sendMessage(attendeeMessage).then(data => { });
        }
      });
      sendAdminEmail(user);
    });
  
  }


  const sendAdminEmail = async (user) => {
    const adminMessage = {
      "subject": `${user.firstName} ${user.lastName} signed up for the SDSIC 2025 Event`,
      "sender": { "email": "events@sdsconsortium.com", "name": "events@sdsconsortium.com" },
      "to": [{ "name": "trevor@sdsconsortium.com", "email": "trevor@sdsconsortium.com" }],
      "htmlContent":  `From user ${user.firstName} ${user.lastName}, <p>from company: ${user.companyName}, has signed up for the 2025 Event,
                      with email: ${user.email}</p>
                      <p>User has accessibility needs?: ${user.accessibility}</p>
                      <p>User was interested in Sponsoring: ${user.sponsorInterest}</p>
                      <p>As a <b>${user.industry}</b></p>`
    }

    // make request to api to send the admin message
    sendMessage(adminMessage).then(data => { });
    
  };

  const sendEmailReceipt = async (receiptUrl, receiptEmail, receiptName) => {
    const numberOfUsers = props.userList.length    

    var emailList = "Emails with conference details have been sent to each of your registrants: "

    props.userList.forEach(user => {
      emailList = emailList + `${user.email}, `
    })

    const mailMessage = {
      "subject": `Thank you for your payment for ${numberOfUsers} SDSIC attendees`,
      "sender": { "email": "events@sdsconsortium.com", "name": "events@sdsconsortium.com" },
      "replyTo": { "email": "events@sdsconsortium.com", "name": "events@sdsconsortium.com" },
      "to": [{ "name": `${receiptEmail}`, "email": `${receiptEmail}` }],
      "htmlContent": `Hi ${name}, <p>Thank you for registering ${numberOfUsers} attendees for the SDSIC 2025 Event in Chattanooga City, TN;</p>                         
                      <p>your payment was successful and you can find a link to your receipt below</p>                              
                      <p>Get your receipt here: ${receiptUrl}</p>                       
                      <p>${emailList}</p>
                      <p>You may want to remind each person that their emails may go to spam.</p>
                      <p>Thank you!</p>
                      <p>the SDSIC Leadership team</p>`
    }

    await sendMessage(mailMessage).then(data => { });

  };

  return (
    <React.Fragment>
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">Pay with card</span>
      </h4>
      <form onSubmit={handleSubmit}>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-name">Name on card</label>
            <input
              id="cc-name"
              type="text"
              className="form-control"
              // value={props.firstName + ' ' + props.lastName}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-email">Email (for payment receipt)</label>
            <input
              id="cc-email"
              type="text"
              className="form-control"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-number">Card Number</label>
            <CardNumberElement
              id="cc-number"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="expiry">Expiration Date</label>
            <CardExpiryElement
              id="expiry"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement
              id="cvc"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <hr className="mb-4" />
        <button className="btn btn-dark w-100" type="submit" disabled={loading}>
          {loading ? <div className="spinner-border spinner-border-sm text-light" role="status"></div> : `PAY $ ${props.amount} USD`}
        </button>
        {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
      </form>
    </React.Fragment>
  );
}